import { Site } from "@libry-content/types";

type SearchIntegrationEnvironment = NonNullable<Site["librarySystem"]>["searchIntegration"];

export type SiteSearchConfig =
  | {
      isSearchIntegrationEnabled: true;
      searchIntegrationEnvironment: SearchIntegrationEnvironment;
      searchApiUrl: string;
      libraryCatalogueId: string;
    }
  | {
      isSearchIntegrationEnabled: false;
      searchIntegrationEnvironment: null;
      searchApiUrl: null;
      libraryCatalogueId: null;
    };

const isEnabled = (searchEnv: SearchIntegrationEnvironment) => searchEnv === "prod" || searchEnv === "staging";

export const getSearchApiUrl = (searchEnv: SearchIntegrationEnvironment) =>
  searchEnv === "prod" ? "https://search.data.bs.no/api/v1" : "https://search.data-staging.bs.no/api/v1";

export const getSearchConfig = ({ librarySystem }: Pick<Site, "librarySystem"> = {}): SiteSearchConfig =>
  librarySystem && isEnabled(librarySystem.searchIntegration) && librarySystem?.database
    ? {
        isSearchIntegrationEnabled: true,
        searchIntegrationEnvironment: librarySystem?.searchIntegration,
        libraryCatalogueId: librarySystem.database,
        searchApiUrl: getSearchApiUrl(librarySystem.searchIntegration),
      }
    : {
        isSearchIntegrationEnabled: false,
        searchIntegrationEnvironment: null,
        searchApiUrl: null,
        libraryCatalogueId: null,
      };
